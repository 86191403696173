<template>
  <button id="teste-google" v-google-signin-button="value" class="google-signin-button">Fazer Integração</button>
</template>

<script>
import GoogleSignInButton from 'vue-google-signin-button-directive';
import axios from 'axios';

export default {
  directives: {
    GoogleSignInButton
  },
  data: () => ({
    value:{
      clientId: '753585437519-c6kn7emvogui3emd98ji8811m3eue21l.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/youtube.readonly'
    }
  }),
  methods: {
    OnGoogleAuthSuccess (idToken) {
        //console.log(idToken);
        axios.post(process.env.VUE_APP_API_HOST+'/connection/google/code', idToken, {
            headers: {
                Authorization: this.$route.params.auth
            }
        }).finally(() => window.top.postMessage('reloadGoogleConections', '*'));
    },
    OnGoogleAuthFail (error) {
      //console.log(error)
    },
    simulateClick(){
      setTimeout(() => {
        document.getElementById('teste-google').click();
        document.getElementById('teste-google').classList.add('google-signin-button2')
      }, 1000);
    }
  },
  mounted(){
    this.simulateClick();
  }
}
</script>

<style lang="scss" scoped>
.google-signin-button{
    border: none;
    background: rgba(255, 255, 255, 0);
    color: rgba(255, 255, 255, 0);
}

.google-signin-button2{
    border: none;
    background: rgba(33, 51, 210, 0.1) !important;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 5px;
    width: 231.25px;
    height: 45px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #2133D2 !important;
}
</style>